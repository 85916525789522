<template>

<app-content-alert class="event-proposed" :danger="isCancelled">

	<template v-if="!isCancelled">

		This is a <b>proposed event</b> and the recipient of the proposal must either accept or reject for it to be scheduled. You can discuss the proposal and any potential changes using the chat feature.

	</template>

	<template v-if="isCancelled">

		This is proposed event has been cancelled and can no longer be scheduled.

	</template>

</app-content-alert>

</template>

<script>

export default {

	props: ['item'],

	computed: {

		isCancelled: function() {

			return this.item.cancelled

		}

	}

}

</script>

<style scoped>

.is-mobile .event-proposed {
	grid-column: 1/5;
}

</style>